/**
 * Copyright © 2023 - BVA Architects (Pty) Ltd. All rights reserved.
 *
 * @author Chanelle Bösiger <scheeperschanelle@gmail.com>
 */

/*** NETFLIX SANS FONT ***/
@font-face {
    font-family: NetflixSans_Thin;
    src: url(assets/fonts/NetflixSansThin.ttf);
}

@font-face {
    font-family: NetflixSans_Light;
    src: url(assets/fonts/NetflixSansLight.ttf);
}

@font-face {
    font-family: NetflixSans_Regular;
    src: url(assets/fonts/NetflixSansRegular.ttf);
}

@font-face {
    font-family: NetflixSans_Medium;
    src: url(assets/fonts/NetflixSansMedium.ttf);
}

@font-face {
    font-family: NetflixSans_Black;
    src: url(assets/fonts/NetflixSansBlack.ttf);
}

@font-face {
    font-family: NetflixSans_Bold;
    src: url(assets/fonts/NetflixSansBold.ttf);
}

@font-face {
    font-family: NetflixSans_Icon;
    src: url(assets/fonts/NetflixSansIcon.ttf);
}

/*** GENERAL ***/
.App,
#root,
html,
body {
    font-weight: 300;
    color: #000000;
    background-color: #ffffff;
    font-size: 0.95rem;
    font-family: NetflixSans_Regular, Calibri, Helvetica, sans-serif;
}

#root, html, .App, body {
    height: auto;
    min-height: 100vh;
    position: relative;
}

header h3, .row h3 {
    color: #80c040;
    text-transform: uppercase;
    padding: 20px 0;
}

.row h3 {
    padding: 20px 12px;
    margin: 0;
}

li {
    list-style-position: inside;
}

/*** NAVIGATION ***/
#logo {
    max-height: 80px;
    max-width: 128px;
}

.navbar-nav .nav-link {
    color: #000000;
    font-weight: bold;
    margin: 0.5rem;
    padding: 0.25rem;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.navbar-nav .dropdown .dropdown-menu .dropdown-item:hover,
.navbar-nav .dropdown .dropdown-menu .dropdown-item.active,
.navbar-nav .dropdown .dropdown-menu .dropdown-item.show {
    background-color: #ffffff;
    color: #a8a8a8;
    border-bottom: 3px solid #80c040;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}

/*** LANDING ***/
.landing_carousel_large {
    display: block;
}

.landing_carousel_responsive {
    display: none;
}

@media screen and (max-width: 575px) {
    .landing_carousel_large {
        display: none;
    }

    .landing_carousel_responsive {
        display: block;
    }
}

.property-container {
    position: relative;
}

.property_img_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    color: #ffffff;
    animation: show 1s linear;
    animation-fill-mode: forwards;
}

.property_img_overlay h6 {
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

@keyframes show {
    0% {
        display: none;
        opacity: 0;
    }
    99% {
        display: none;
    }
    100% {
        display: inline-block;
        opacity: 1;
    }
}

.property-container:hover .property_img_overlay {
    animation: hide 1s linear;
    animation-fill-mode: forwards;
}

@keyframes hide {
    0% {
        display: inline-block;
        opacity: 1;
    }
    99% {
        display: inline-block;
    }
    100% {
        display: none;
        opacity: 0;
    }
}

/*** ABOUT US ***/
.practice_staff_img {
    width: 135px;
}

.practice_staff_name {
    text-align: center;
    color: #858585;
}

.practice_staff_role {
    font-style: italic;
}

#about_map {
    padding-bottom: 20px;
}

/*** COMMUNITY ***/
.carousel-indicators, .carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: #00000066 !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    padding-top: 20px;
    padding-bottom: 20px;
}

.carousel-indicators .active {
    background-color: #80c040 !important;
}

/* CLIENT FEEDBACK */
.referral_card {
    border: 1px solid #959DA528 !important;
    box-Shadow: rgba(0, 0, 0, 0.14) 0 5px 15px;
}

.referral_card .card-body {
    color: #808080;
    font-style: italic;
}

#referral_img {
    display: block;
}

#referral_img_small {
    display: none;
}

#referral_img_medium {
    display: none;
}

#referral_img_large {
    display: none;
}

@media screen and (max-width: 1400px ) and (min-width: 1200px) {
    #referral_img {
        display: none;
    }

    #referral_img_small {
        display: none;
    }

    #referral_img_medium {
        display: none;
    }

    #referral_img_large {
        display: block;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 1200px ) and (min-width: 990px) {
    #referral_img {
        display: none;
    }

    #referral_img_small {
        display: none;
    }

    #referral_img_medium {
        display: block;
        padding-bottom: 10px;
    }

    #referral_img_large {
        display: none;
    }
}

@media screen and (max-width: 990px) {
    #referral_img {
        display: none;
    }

    #referral_img_small {
        display: block;
        padding-bottom: 10px;
    }

    #referral_img_medium {
        display: none;
    }

    #referral_img_large {
        display: none;
    }
}

/*** PROJECTS ***/
.portfolio_gallery_large {
    display: block !important;
}

.portfolio_gallery_medium {
    display: none;
}

.portfolio_gallery_small {
    display: none;
}

.portfolio_carousel_img {
    max-height: 80vh;
    object-fit: contain;
}

.portfolio_carousel_video {
    max-height: 80vh;
    height: 100%;
    width: 100%;
}

.modal-dialog, .modal-content {
    width: fit-content !important;
}

@media screen and (max-width: 1000px) {
    .portfolio_gallery_large {
        display: none !important;
    }

    .portfolio_gallery_medium {
        display: block;
    }

    .portfolio_gallery_small {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .portfolio_gallery_large {
        display: none !important;
    }

    .portfolio_gallery_medium {
        display: none;
    }

    .portfolio_gallery_small {
        display: block;
    }
}

/*** PROJECT LIST ***/
.project_unordered_list {
    padding-left: 0;
}

/*** CONTACT ***/
@media screen and (max-width: 990px) {
    .contact_content {
        padding-bottom: 250px;
    }
}

.form-group.required .control-label:after {
    content: "*";
    font-weight: bold;
    font-size: 0.8rem;
    color: red;
}

.contact-submit-btn {
    position: relative;
    height: 40px;
    width: 75px;
    display: block !important;
    float: right;
}

.contact-submit-responsive-btn {
    display: none !important;
}

#contact_btn_initial_content,
#contact_btn_success_content,
#contact_btn_error_content {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#contact_btn_success_content {
    display: none;
    color: #80c040;
    font-size: 25px;
}

#contact_btn_error_content {
    display: none;
    color: #FF0000FF;
    font-size: 25px;
}

@media screen and (max-width: 576px) {
    .contact-submit-btn {
        display: none !important;
    }

    .contact-submit-responsive-btn {
        display: block !important;
    }
}

.map-container {
    border-radius: 0.375rem;
    margin: 20px 0;
    height: 50%;
    width: 100%;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

.css-lzr3bi-LoadingOverlayWrapperBase {
    background: none !important;
}

/*** ICONS ***/
.social-icons {
    font-size: 24px;
    color: #000000;
}

.social-icons:hover {
    color: #80c040;
}

/*** FOOTER ***/
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    background-color: #ffffff;
    height: 50px;
}

#copyright-col {
    height: fit-content;
}

#copyright-content {
    font-size: 0.9rem;
    color: #808080;
    margin: 0;
}

.contact_social .social-link {
    float: left;
    padding-right: 10px;
}

#social-row .social-link {
    float: right;
    padding-left: 10px;
}

@media only screen and (max-width: 768px) {
    main {
        padding-bottom: 20px;
    }
}
